import React from "react"
import { Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import { RESILIENCE_TEST } from "./../gql"
import LayoutContainer from "./../components/layout"
import Throbber from "./../components/throbber"
import resilienceSchemaImage from "./../assets/images/resilience-schema.svg"
import bodyIcon from "./../assets/icons/body-large.svg"
import heartIcon from "./../assets/icons/heart-large.svg"
import mindIcon from "./../assets/icons/mind-large.svg"
import spiritIcon from "./../assets/icons/spirit-large.svg"
import { useTranslation } from "react-i18next"

const categories = [
  {
    icon: bodyIcon,
    titleKey: "resilience.categories.title_body",
    descriptionKey: "resilience.categories.description_body",
  },
  {
    icon: heartIcon,
    titleKey: "resilience.categories.title_heart",
    descriptionKey: "resilience.categories.description_heart",
  },
  {
    icon: mindIcon,
    titleKey: "resilience.categories.title_mind",
    descriptionKey: "resilience.categories.description_mind",
  },
  {
    icon: spiritIcon,
    titleKey: "resilience.categories.title_spirit",
    descriptionKey: "resilience.categories.description_spirit"
  },
]

const ResilienceIntro = ({ location }) => {
  const { loading, error, data } = useQuery(RESILIENCE_TEST)
  const { t } = useTranslation()

  if (loading)
    return (
      <LayoutContainer seoTitle="Resilience intro" path={location.pathname}>
        <Throbber loadingText="Loading..." relative />
      </LayoutContainer>
    )

  if (error)
    return (
      <LayoutContainer seoTitle="Resilience intro" path={location.pathname}>
        <div className="center-align">
          <p className="text">{error.message}</p>
        </div>
      </LayoutContainer>
    )

  const { resilienceTest } = data
  return (
    <LayoutContainer seoTitle="Resilience intro" path={location.pathname}>
      <div className="row">
        <div className="col l8 offset-l2 s12">
          <div className="center-align">
            <h1 className="big-title uppercase no-margin">
              {t("resilience.title_resilience")}
            </h1>
            <p className="text margin--top-10 no-marginbottom">
              {t("resilience.sub_text_initial")}
            </p>
            <p className="text margin--top-10 no-marginbottom">
              {t("resilience.sub_text_bottom")}
            </p>
          </div>
        </div>
        <div className="col l6 offset-l3 s12 margin--top-40">
          <div className="center-align">
            <img src={resilienceSchemaImage} alt="Resilience result schema" />
          </div>
        </div>
      </div>
      <div className="row margin--top-40">
        {categories.map(category => (
          <div className="col l3 m6 s12" key={t(category.titleKey)}>
            <div className="margin--bottom-60">
              <div className="center-align">
                <div className="icon">
                  <img src={category.icon} alt={t(category.titleKey)} />
                </div>
                <h2 className="subtitle uppercase">
                  {t(category.titleKey)}
                </h2>
              </div>
              <p className="feature-text">
                {t(category.descriptionKey)}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col s12">
          <div className="center-align">
            <Link
              className="button"
              to={
                resilienceTest.completed
                  ? "/resilience-result"
                  : "/resilience-test"
              }
            >
              {t("resilience.start_link")}
            </Link>
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}

export default ResilienceIntro
